.container {
  text-align: center;
}

.banner{
  background-color: #88ced8;
  box-shadow: -1px 1px 5px 2px rgba(0.1,0.1,0.1,0.75);

}
.form {
background-color: #a7b9d8;
border-radius: 5%;
margin: auto;
width: 50%;
padding: 15px;
box-shadow: -1px 1px 5px 2px rgba(0.1,0.1,0.1,0.75);
}

.search-box {
  width: 250px;
  margin: auto;
}

.instructions {
  font-size: 12px;
  border: 1px solid #000000;
  width: 350px;
  margin: auto;
  padding: 15px;
  text-align: left;
}

.confirmation {
  font-size: 18px;
  color: #16988c;
  width: 350px;
  padding: 15px;
  margin: auto;
}

/* boPalette = ['#1097cf','#1d566d','#88ced8','#16988c','#a5d4b4','#5a5a83','#a7b9d8','#606d66'] */

.feature-link {
  height: 100px;
  width: 175px;
  margin-right: 16px;
  background-color: #88ced8;
  
}

.feature-link:hover, .home-button:hover {
  background-color:#1097cf;
  transition: 0.7s;
}

.home-button {
  background-color: #a7b9d8;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
  font-size: 50px;
  color: #a7b9d8; 
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

/* boPalette = ['#1097cf','#1d566d','#88ced8','#16988c','#a5d4b4','#5a5a83','#a7b9d8','#606d66'] */
